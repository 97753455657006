.alert-shown {
    .navbar {
        margin-top: 60px;
    }
    .app-container {
        margin-top: 130px;
    }
    .aside {
        margin-top: 60px;
        height: calc(100% - 60px);
    }
    &.hide {
        .navbar {
            margin-top: 0;
        }
        .app-container {
            margin-top: 70px;
        }
        .aside {
            margin-top: 0;
            height: 100%;
        }
    }
}
.alert {
    border-radius: $default-border-radius;
    .title {
        font-size: $font-size-sm;
        margin-bottom: 5px;
        .ion {
            font-size: $font-size-base
        }
    }
}
.alert-info {
    text-align: left;
    background-color: $bt-brand-primary-faint;
    color: white;
    border-color: transparent;
    border-left: 5px solid $bt-brand-primary;
    a {
        color: white;
        text-decoration: underline;
    }
}
.dark {
    .alert {
        &.alert-info {
            background: $bt-brand-primary-dark;
            border-color: transparent;
            border-color: lighten($bt-brand-primary-dark,5);
            color: $header-color-dark;
        }
    }
}
.text-info { color: $bt-brand-primary  !important;}
