@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

$primary: #6837fc;
$danger: #ef4d56;
$text-icon-dark: #1a2634;
$body-bg: #fff;
$link-color: $primary;
$theme-colors: (
  primary: $primary,
  danger: $danger,
  // add any additional color below
);

//Buttons
$btn-height: 48px;
$btn-line-height: 48px;
$btn-padding-y: 0;
$btn-font-weight: 700;
$btn-border-radius: 12px;
$btn-font-size: 16px;
$btn-padding-x: 20px; // todo, $spacer PR should change this to $spacer*5
$btn-padding-y-sm: 0;
$btn-padding-x-sm: 16px;
$btn-font-size-sm: 14px;
$btn-padding-y-lg: 0;
$btn-padding-x-lg: 24px;
$btn-font-size-lg: 18px;
$btn-border-radius-sm: 10px;
$btn-border-radius-lg: 14px;
$btn-disabled-opacity: 0.32;
$btn-hover-bg: #4e25db;
$btn-active-bg: #3919b7;
$btn-secondary-bg: #656d7b14;
$btn-secondary-hover-bg: rgba(101, 109, 123, 0.16);
$btn-secondary-active-bg: rgba(101, 109, 123, 0.24);
$btn-outline-hover-bg: rgba(149, 108, 255, 0.08);
$btn-outline-active-bg: rgba(149, 108, 255, 0.16);
$btn-danger-hover: #cd384d;
$btn-danger-active: #ac2646;
.btn {
  border-width: 0;
}

//Modal

$modal-md: 600px;
$modal-lg: 900px;

//Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1200px
);

$container-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 940px,
  xl: 1140px,
  xxl: 1140px
);

//Typography
$font-family-base: 'OpenSans', sans-serif;
$font-size-sm: 0.825rem;
$line-height-base: 1.375;
$line-height-sm: 20px;
$line-height-lg: 24px;
$line-height-xlg: 28px;
$line-height-xsm: 18px;
$line-height-xxsm: 24px;
$body-color: $text-icon-dark;
$font-sizes: (
  small: 14px,
  caption: 13px,
  captionSmall: 12px,
  captionXSmall: 11px,
);
$h1-font-size: 42px;
$h1-line-height: 46px;
$h2-font-size: 34px;
$h2-line-height: 40px;
$h3-font-size: 30px;
$h3-line-height: 40px;
$h4-font-size: 24px;
$h4-line-height: 32px;
$h5-font-size: 20px;
$h5-line-height: 30px;
$h6-font-size: 16px;
$h6-line-height: 24px;